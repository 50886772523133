import { type SITES } from "@/lib/request/types";

type DeviceMap = {
  android?: number;
  desktop: number;
  ios?: number;
  smartphone: number;
  tablet: number;
};

export type SiteProperties = (typeof SITES)[number];

type SpaceIdRecord = {
  // register each site as a key
  [K in SiteProperties]: DeviceMap;
} & {
  // support index signature look up for dynamic site values
  [key: string]: DeviceMap;
};

// mapping from the old "App" spaceids to the new "webview" ones
export const NEW_WEBVIEW_SPACE_IDS: Record<number, number> = {
  "119761399": 1197812734, // ios typo
  "1197613990": 1197812734, // ios
  "1197613991": 1197812735, // android
};

export const SPACE_IDS: SpaceIdRecord = {
  // See https://i13n.data.yahoo.com/wizard/?lookup_val=%2Fsite%2Fy4c%2Fdiscovery_hub&lookup_type=path_sid&currtab=hotlist
  creators: {
    android: 1197812732, // /site/y4c/discovery_hub/mobile
    desktop: 1197812731, // /site/y4c/discovery_hub/desktop
    ios: 1197812732, // /site/y4c/discovery_hub/mobile
    smartphone: 1197812732, // /site/y4c/discovery_hub/mobile
    tablet: 1197812733, // /site/y4c/discovery_hub/tablet
  },
  entertainment: {
    desktop: 1197809029,
    smartphone: 1197809030,
    tablet: 1197809029,
  },
  frontpage: {
    desktop: 2023538075,
    smartphone: 1197228339,
    tablet: 2023538075,
  },
  frontpage_modal: {
    desktop: 1197792446,
    smartphone: 1197283426,
    tablet: 1197792446,
  },
  lifestyle: {
    desktop: 980751902,
    smartphone: 980751903,
    tablet: 980751902,
  },
  news: {
    android: 1197812735,
    desktop: 1197800621,
    ios: 1197812734,
    smartphone: 1197800623,
    tablet: 1197800621,
  },
  tech: {
    desktop: 1197810144,
    smartphone: 1197810145,
    tablet: 1197810144,
  },
};
