import { type Config as RapidConfig } from "@vzmi/types-rapid";
import { type BenjiPlatform } from "@yahoo-creators/monetization/src/benji";
import { type DeviceSize } from "@yahoo-news/util";
import {
  GAM_EMEA_CONSENT_KEYS,
  GAM_FIRST_PARTY_CONSENT_KEYS,
  GAM_NPA_CONSENT_KEYS,
  GAM_NPA_NON_CONSENT_KEYS,
  GAM_REST_OF_WORLD_CONSENT_KEYS,
  GAM_US_CONSENT_KEYS,
} from "@/configs/benji";
import { NEW_WEBVIEW_SPACE_IDS, SPACE_IDS } from "@/configs/spaceId";
import { beaconError } from "@/lib/beacon";
import { type CaasArticle } from "@/types/Caas";
import { isPrestigeArticle } from "./article/layout";
import { type RequestContext } from "./request/types";

export const COMMON_STREAM_LINK_I13N_PARAMS = {
  ct: "story",
  elm: "hdln",
  elmt: "ct",
  grpt: "singlestory",
  itc: 0,
  p_sys: "jarvis",
  pkgt: "orphan_img",
};

export const COMMON_NTK_LINK_I13N_PARAMS = {
  ct: "story",
  elm: "hdln",
  itc: 0,
  p_sys: "jarvis",
};

const getPageDesignForArticle = (
  isModal: boolean,
  isPrestige: boolean,
  webview: boolean,
): string => {
  const basePageDesign = webview ? "webview" : isModal ? "modal" : "non_modal";
  return isPrestige ? `prestige-${basePageDesign}` : basePageDesign;
};

// cobrand partner value in the format partnername_partner
const getCobrandValueForPartner = (partner: string): string => {
  const cbe = partner && partner !== "none" ? `${partner}-partner` : "";
  return cbe;
};

export const getBaseI13nForArticle = ({
  article,
  articleIndex,
  isModal,
  isPerpetualPost,
  requestContext: { colo, intl, features, site, webview },
}: {
  article: CaasArticle;
  articleIndex: number;
  isModal: boolean;
  isPerpetualPost?: boolean;
  requestContext: RequestContext;
}) => {
  const { contentMeta } = article.data || {};
  const {
    pct = "",
    uuid = "",
    videoPosition = "",
  } = contentMeta?.contentI13n || {};

  const allowedProviderCategories = [
    "astrology",
    "fashion_style_beauty",
    "fitness",
    "food",
    "home_diy",
    "humor",
    "nature",
    "parenting",
    "technology",
    "travel",
    "wellness",
  ];

  const { modifiedDate, publishDate } = contentMeta?.dates || {};
  const adMeta = contentMeta?.adMeta || {};
  const authorName = article.schema?.default?.author?.name || "";
  const hostedType = contentMeta?.contentIdentifiers?.hostedType || "";
  const isCreatorContent =
    article?.data?.contentMeta?.contentIdentifiers?.isCreatorContent;
  const isPrestige =
    (isCreatorContent || !!features.prestige) && isPrestigeArticle(article);
  const providerCategories =
    contentMeta?.contentTags?.normalizedTags?.ymedia?.provider_category;

  // Providers can only choose 1 of the allowed categories per article in story settings.
  // This filters out the default categories (site_lifestyle, site_default, etc).
  const providerCategory = providerCategories?.filter((category: string) =>
    allowedProviderCategories.includes(category),
  );
  const providerName = article.schema?.default?.provider?.name || "";
  const sectionContentCategoryPath =
    contentMeta?.siteSections?.sectionContentCategoryPath || "|";

  return {
    abk: "",
    colo,
    contentcategorypath: sectionContentCategoryPath,
    displayts: modifiedDate?.timestamp || publishDate?.timestamp || "",
    expn: isPerpetualPost ? "perpetual-post" : "",
    mrkt: intl,
    p_cpos: articleIndex + 1,
    p_hosted: hostedType || "hosted",
    p_sec: "default",
    pcp: authorName || providerName || "",
    pct,
    pd: getPageDesignForArticle(isModal, isPrestige, webview),
    pstaid: uuid,
    pstcat: isCreatorContent
      ? providerCategory[0]
      : contentMeta?.categoryLabel?.toLowerCase() || "",
    pt: "content",
    site: adMeta.site || site,
    theme: contentMeta?.contentIdentifiers?.isCreatorContent ? "y4c" : "",
    ver: "yc",
    vidPos: videoPosition,
  };
};

export const getBenjiConfigForArticle = ({
  article,
  articleIndex,
  deviceSize,
  isModal,
  isPerpetualPost,
  requestContext,
}: {
  article: CaasArticle;
  articleIndex: number;
  deviceSize: DeviceSize;
  isClientNav: boolean;
  isModal: boolean;
  isPerpetualPost?: boolean;
  requestContext: RequestContext;
}) => {
  const { pct = "", uuid = "" } = article.data?.contentMeta?.contentI13n || {};
  const { lmsid = "" } = parseArticleAdMetaRs(article);

  return {
    i13n: {
      ...getBaseI13nForArticle({
        article,
        articleIndex,
        isModal,
        isPerpetualPost,
        requestContext,
      }),
      baseContentType: pct,
      bucket: requestContext.bucket,
      contentSite: requestContext.site,
      contentType: pct,
      designtype: "default", // TODO
      dir: "ltr",
      feature: Object.keys(requestContext.features),
      hashtag: (article.data?.contentMeta?.adMeta?.hashtag || "").split(";"),
      intl: requestContext.intl,
      lang: requestContext.lang,
      lmsid,
      lpstaid: uuid,
      mode: "normal",
      pageName: "deeplink",
      region: requestContext.region,
      spaceid: String(
        getSpaceId({ article, deviceSize, site: requestContext.site }),
      ),
      ynet: "0",
    },
  };
};

export const getBenjiConfigForCreatorHomepage = ({
  deviceSize,
  requestContext,
}: {
  deviceSize: DeviceSize;
  requestContext: RequestContext;
}) => {
  const { bucket, intl, lang, region, site } = requestContext;
  return {
    i13n: {
      abk: "",
      bucket,
      designtype: "viperStream",
      dir: "ltr",
      intl,
      lang,
      mode: "normal",
      mrkt: intl,
      pageType: "default",
      pt: "minihome",
      region,
      site: "fp",
      spaceid: String(getSpaceId({ deviceSize, site })),
      ver: "yc",
      version: region,
      ynet: "0",
    },
  };
};

export const getBenjiConsent = (
  jurisdiction: string,
  consentFields?: string[],
) => {
  const consent = {
    allowFirstPartyAds: true,
    allowOnlyLimitedAds: false,
    allowOnlyNonPersonalizedAds: false,
  };
  if (!consentFields) {
    return consent;
  }

  // Logic here was copied from express-monetization
  // https://git.ouryahoo.com/monetization/monorepo/blob/main/packages/express-monetization/src/privacyCheck.ts

  // ---------- Check rules for limted ads ----------
  if (jurisdiction === "GDPR") {
    // Apply consent rules for EMEA
    // all of these keys must be opted in, otherwise show limited ads
    consent.allowOnlyLimitedAds = GAM_EMEA_CONSENT_KEYS.some(
      (key) => consentFields.includes(key) === false,
    );
  } else if (jurisdiction === "US") {
    // Apply consent rules for US
    // all of these keys must be opted in, otherwise show limited ads
    consent.allowOnlyLimitedAds = GAM_US_CONSENT_KEYS.some(
      (key) => consentFields.includes(key) === false,
    );
  } else {
    // Apply consent rules for rest of the world
    // all of these keys must be opted in, otherwise show limited ads
    consent.allowOnlyLimitedAds = GAM_REST_OF_WORLD_CONSENT_KEYS.some(
      (key) => consentFields.includes(key) === false,
    );
  }

  // ---------- Check rules for non-personalized ads -----------
  const hasNPANonConsentKeys = GAM_NPA_NON_CONSENT_KEYS.every(
    (key) => consentFields.includes(key) === false,
  );
  // If opted out allowed dissent keys but opted in all consent ones, show non-personalized ads
  consent.allowOnlyNonPersonalizedAds =
    hasNPANonConsentKeys &&
    GAM_NPA_CONSENT_KEYS.every((key) => consentFields.includes(key) !== false);

  // ---------- Check rules for first party ads -----------
  consent.allowFirstPartyAds = GAM_FIRST_PARTY_CONSENT_KEYS.every(
    (key) => consentFields.includes(key) === true,
  );

  return consent;
};

export const getVideoConfig = ({
  deviceSize,
  requestContext,
}: {
  deviceSize: DeviceSize;
  requestContext: RequestContext;
}) => {
  const { lang, region, site } = requestContext;
  return {
    lang,
    pageSpaceId: getSpaceId({ deviceSize, site }),
    region,
    site,
  };
};

/**
 * Parses the article's `adMeta.rs` string, which is formatted like:
 *
 * ```
 * "lmsid:a0a6T00000PvqBIQAZ;revsp:fox_weather_videos_202;lpstaid:4891b17b-4789-3b6a-b83d-25668b27705c;..."
 * ```
 *
 * and converts it into a key-value mapping like
 *
 * ```
 *  {
 *    lmsid: "a0a6T00000PvqBIQAZ",
 *    lpstaid: "4891b17b-4789-3b6a-b83d-25668b27705c",
 *    revsp: "fox_weather_videos_202",
 *    ...
 *  }
 * ```
 */
export const parseArticleAdMetaRs = (
  article: CaasArticle,
): Record<string, string> =>
  (article.data?.contentMeta?.adMeta?.rs || "")
    .split(";")
    .map((serializedEntry) => serializedEntry.split(":"))
    .filter((entry): entry is [string, string] => entry.length === 2)
    .reduce(
      (entries, [key, value]) => ({ ...entries, [key]: value }),
      {} as Record<string, string>,
    );

const siteAttributePattern = /^([^=]+)="(.*)"$/;
const badAttributePattern = /%-/g;
const parseArticleAdMetaSiteAttribute = (
  serializedAttribute: string,
): [string, string] | null => {
  const matches = serializedAttribute.match(siteAttributePattern);
  if (!matches) {
    return null;
  }

  try {
    // sports articles sometimes have %- in the hashtag value
    return [
      matches[1],
      decodeURIComponent(matches[2].replace(badAttributePattern, "-")),
    ];
  } catch (e: any) {
    // TODO: temporary code to see if we can trigger this just to validate / see how often we hit this
    // decodeURIComponent('hello%91world') will throw URIError: URI malformed
    // We must be getting some bad encodings from CaaS...
    if (e) {
      e.serializedAttribute = serializedAttribute;
    }
    beaconError("serializedAttribute", e);
    return null;
  }
};

/**
 * Parses the article's `adMeta.site_attribute` string, which is formatted like:
 *
 * ```
 * "wiki_topics=\"Francis_Scott_Key_Bridge_%28Baltimore%29;Container_ship;Baltimore;Tuesday_Morning\" ctopid=\"1106000;1107000\" ..."
 * ```
 *
 * and converts it into a key-value mapping like
 *
 * ```
 *  {
 *    ctopid: "1106000;1107000",
 *    wiki_topics: "Francis_Scott_Key_Bridge_(Baltimore);Container_ship;Baltimore;Tuesday_Morning",
 *    ...
 *  }
 * ```
 */
export const parseArticleAdMetaSiteAttributes = (
  article: CaasArticle,
): Record<string, string> =>
  (article.data?.contentMeta?.adMeta?.site_attribute || "")
    .split(/\s+/)
    .map(parseArticleAdMetaSiteAttribute)
    .filter((entry): entry is [string, string] => entry?.length === 2)
    .reduce(
      (entries, [key, value]) => ({ ...entries, [key]: value }),
      {} as Record<string, string>,
    );

export const getRapidConfigForArticle = ({
  article,
  articleIndex,
  deviceSize,
  isClientNav,
  isModal,
  isPerpetualPost,
  isSeedArticleShoppable,
  requestContext,
  referrerUrl,
  requestID,
}: {
  article: CaasArticle & { source?: undefined | string };
  articleIndex: number;
  deviceSize: DeviceSize;
  hasEditorialReadMore?: boolean;
  isClientNav: boolean;
  isModal: boolean;
  isPerpetualPost?: boolean;
  isSeedArticleShoppable?: boolean;
  referrerUrl: string;
  requestID: string;
  requestContext: RequestContext;
}): RapidConfig => {
  return {
    async_all_clicks: true,
    // @ts-ignore
    click_timeout: 300,
    client_only: 1,
    compr_type: "deflate",
    dwell_on: true,
    keys: {
      ...getBaseI13nForArticle({
        article,
        articleIndex,
        isModal,
        isPerpetualPost,
        requestContext,
      }),
      _R: referrerUrl,
      _rid: requestID, // temp solution, wait for Y-RID from header https://ouryahoo.atlassian.net/browse/YCPI-5303
      // TODO: _yrid
      navtype: isClientNav ? "client" : "server",
      pl2: "seamless-article",
      seed_shoppable: isSeedArticleShoppable ? "1" : "0",
      uh_vw: 0,
    },
    pageview_on_init: true,
    perf_navigationtime: 2,
    perf_resourcetime: 1,
    spaceid: getSpaceId({ article, deviceSize, site: requestContext.site }),
    test_id: requestContext.bucket || [],
    track_right_click: true,
    tracked_mods_viewability: [],
    viewability: true,
    webworker_file: "/__rapid-worker-1.2.js",
    yql_host: "udc.yahoo.com",
  };
};

export const getBenjiPlatform = ({
  site,
}: {
  site: string;
}): BenjiPlatform | undefined => {
  // if the spaceid maps to a known platform, use it for the benji paths
  const spaceId = getSpaceId({ site });
  if (SPACE_IDS[site]?.android === spaceId) {
    return "and";
  }
  if (SPACE_IDS[site]?.ios === spaceId) {
    return "ios";
  }
};

export const getSpaceId = ({
  article,
  deviceSize,
  site,
}: {
  article?: CaasArticle;
  deviceSize?: DeviceSize;
  site: string;
}): number => {
  // TODO: https://ouryahoo.atlassian.net/browse/YNCX-1047
  // We want to have the webview spaceId come from the CaaS respone in the future
  // in the same contentI13n.spaceIds that currently holds desktop, tablet, smarthphone.
  // Until then, we honor a query param IFF ?webview=1 as a (hopefully) short term workaround.
  // eg Android News app uses &spaceId=1197812735 and iOS News app uses &spaceId=1197812734
  const search = typeof location === "object" ? location.search : "";
  const searchParams = new URLSearchParams(search);
  if (searchParams.get("webview") === "1") {
    const spaceId = searchParams.get("spaceId");
    if (spaceId?.match(/^\d+$/)) {
      // The apps were passing an "App" spaceid, but we really need a "webview" one.
      // Map the app spaceids to webview ones
      const querySpaceId = parseInt(spaceId, 10);
      return NEW_WEBVIEW_SPACE_IDS[querySpaceId] || querySpaceId;
    }
  }
  // END TODO

  let spaceId = 0;

  if (article) {
    spaceId =
      (deviceSize &&
        article.data?.contentMeta?.contentI13n?.spaceIds?.[deviceSize]) ??
      Number(article.data?.contentMeta?.contentI13n?.spaceId);
  } else if (site && deviceSize) {
    spaceId = SPACE_IDS[site]?.[deviceSize];
  }

  if (Number.isNaN(spaceId)) {
    return 0;
  }

  return spaceId;
};

export const getRapidConfigForCreator = ({
  creatorSlug,
  deviceSize,
  isClientNav,
  pageType,
  referrerUrl,
  requestContext,
}: {
  creatorSlug: string;
  deviceSize: DeviceSize;
  pageType?: string;
  isClientNav: boolean;
  referrerUrl: string;
  requestContext: RequestContext;
}): RapidConfig => {
  const { colo, intl, region, site } = requestContext;
  return {
    async_all_clicks: true,
    // @ts-ignore
    click_timeout: 300,
    client_only: 1,
    compr_type: "deflate",
    keys: {
      // _rid - request Id
      _R: referrerUrl,
      colo,
      ct: "creator",
      mrkt: intl,
      navtype: isClientNav ? "client" : "server",
      pct: "creator",
      pstcat: creatorSlug,
      pt: pageType || "minihome",
      site: site,
      theme: "y4c",
      uh_vw: 0,
      // uloc - code field from user location api response
      ver: "yc",
      version: region,
    },
    pageview_on_init: true,
    perf_navigationtime: 2,
    perf_resourcetime: 1,
    spaceid: getSpaceId({ deviceSize, site }),
    test_id: requestContext.bucket || [],
    tracked_mods_viewability: [],
    viewability: true,
    webworker_file: "/__rapid-worker-1.2.js",
    yql_host: "udc.yahoo.com",
  };
};

export const getRapidConfigForSitemap = ({
  deviceSize,
  referrerUrl,
  requestContext,
}: {
  deviceSize: DeviceSize;
  referrerUrl: string;
  requestContext: RequestContext;
}): RapidConfig => {
  const { colo, intl, partner, region, site } = requestContext;
  return {
    async_all_clicks: true,
    // @ts-ignore
    click_timeout: 300,
    client_only: 1,
    compr_type: "deflate",
    keys: {
      // _rid - request Id
      _R: referrerUrl,
      cbe: getCobrandValueForPartner(partner),
      colo,
      mrkt: intl,
      navtype: "server",
      partner,
      pct: "info",
      pt: "utility",
      site: site,
      uh_vw: 0,
      // uloc - code field from user location api response
      ver: "yc",
      version: region,
    },
    pageview_on_init: true,
    perf_navigationtime: 2,
    perf_resourcetime: 1,
    spaceid: getSpaceId({ deviceSize, site }),
    test_id: requestContext.bucket || [],
    tracked_mods_viewability: [],
    viewability: true,
    webworker_file: "/__rapid-worker-1.2.js",
    yql_host: "udc.yahoo.com",
  };
};
